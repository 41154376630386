import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import SEO from "../components/seo"

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "developer.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <SEO title="About | Yojan Regmi" />
      <h1>About Me</h1>
      {/* <img
        className="programmer-image"
        src={programmerIllustration}
        alt="Programmer Illustration"
      /> */}
      <div
        className="programmer-image"
        style={{
          maxWidth: "400px",
        }}
      >
        <Img fluid={data.placeholderImage.childImageSharp.fluid} />
      </div>
      <p>
        Hi There. I am a javascript developer specialised in React and NodeJS.
        Beside programming I love to read books and watch sci-fi movies.
      </p>
    </>
  )
}

export default About
